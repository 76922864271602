<template>
  <div id="inside">
    <div class="container">
      <div
        class="
          float-right
          col-12
          flex-column
          px-2
          pb-4
          d-flex
          order-1 order-lg-3
          justify-content-center
          align-items-center
          col-lg-4
        "
        style=""
      >
        <div class="btn-group btn-group-sm">
          <button
            class="
              btn btn-link
              d-flex
              flex-column
              justify-content-left
              text-decoration-none
            "
            @click="contractsPending()"
          >
            <i class="fa fa-edit fa-fw"></i> contratos
          </button>
          <button
            class="
              btn btn-link
              d-flex
              flex-column
              justify-content-left
              text-decoration-none
            "
            @click="verBoletos()"
          >
            <i class="fa fa-fw fa-file-text-o"></i> meus acordos
          </button>
          <button
            class="
              btn btn-link
              d-flex
              flex-column
              justify-content-left
              text-decoration-none
            "
            @click="cadastro()"
          >
            <i class="fa fa-fw fa-user-o"></i>cadastro</button
          ><button
            class="
              btn btn-link
              d-flex
              flex-column
              justify-content-left
              text-decoration-none
            "
            @click="cadastro()"
          >
            <i class="fa fa-fw fa-key"></i>código de acesso
          </button>
          <!-- <router-link
              to="https://api.whatsapp.com/send?l=pt&amp;phone=5514000000000"
              class="nav-item"
              style="color: #0da0db; text-decoration: none"
              ><a
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                target="_blank"
                ><i class="fa fa-fw fa-whatsapp"></i>whatsapp</a
              ></router-link
            > -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Boletos</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <button class="btn btn-primary mt-3" @click="voltar()">Voltar</button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="card"
            v-for="(value, key, index) in agreements"
            :key="`${key}-${index}`"
          >
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h5 class="">{{ contracts.Produto }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <!-- <a class="btn btn-link btn-sm mt-2 float-right" href="#">Detalhes</a> -->
                  <h6 class="text-primary mt-3 mb-0">
                    Contrato {{ contracts.NumeroTitulo }}
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12"></div>
              </div>
              <div
                class="row border-bottom mt-4 pb-4"
                v-for="(installments, key, index) in value.ParcelasAcordo
                  .ParcelaAcordo"
                :key="`${key}-${index}`"
              >
                <div class="col-xs-8 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Parcela:</p>
                  <h6
                    class="
                      d-inline-flex
                      bg-primary
                      text-light
                      px-2
                      py-2
                      small
                      rounded
                    "
                  >
                    {{ key + 1 }}/{{ sumTotalInstallments() }}
                  </h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Valor:</p>
                  <h6 class="">R$ {{ installments.ValorParcela }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-2" style="">
                  <p class="mb-1 small">Vencimento:</p>
                  <h6 class="small">{{ installments.DataVencimento }}</h6>
                </div>
                <div class="col-xs-8 col-12 col-lg-5" style="">
                  <p class="small mb-1 d-inline-flex">Pagamento:</p>
                  <p class="small d-inline-flex mb-1 ml-2 text-info">
                    {{ installments.StatusParcelaAcordo }}
                  </p>
                  <!-- <a class="btn btn-link mr-1 mb-1 disabled" href="#"><i class="fa fa-fw fa-eye"></i>&nbsp;Ver</a> -->
                </div>
                <div class="col-xs-8 col-12 col-lg-6" style="">
                  <p class="text-info mt-3 mb-1">Linha digitável:</p>
                  <a class="btn btn-link btn-sm mt-2 float-right" href="#"
                    >Copiar</a
                  >
                  <p class="codebar">
                    34196012340120105432102101 020103613131545000
                  </p>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2" style="">
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="downloadBoleto(installments)"> Baixar boleto</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="sendSms(installments.LinhaDigitavel)">&nbsp;Receber por SMS</a>
                  <a class="btn mr-1 mb-1 btn-outline-primary" href="#" @click="sendEmail(installments.LinhaDigitavel)">&nbsp;Receber por e-mail</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style=""></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
} from "@/utils/helpers";

export default {
  name: "HistoricoPagamentos",
  components: {},
  data() {
    return {
      loadDeal: false,
      negociacao: null,
      contracts: {},
      selectDeal: null,
      digitalLine: null,
      agreements: [],
      checkSelectAll: false,
      checkContract: false,
      links: [],
    };
  },
  methods: {
    formatValue,
    formatData,

    ...mapActions("meusAcordos", ["ActionInsertLog"]),

    async insertLog(valueId) {
      this.loading = true;
      try {
        await this.ActionInsertLog({
          DebtorID: this.user.DebtorID,
          DocNumber: this.user.DocNumber,
          ValueID: valueId,
        });

        this.loading = false;
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: error.message,
        });
        this.loading = false;
      }
    },

    async downloadBoleto(value) {
      this.loading = true;
      try {
        const linkSource = `data:application/pdf;base64,${value["Base64"]}`;
        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("target", "_blank");
        const fileName = `boleto-claro.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        await this.insertLog(200);

        this.flashMessage.show({
          timeout: 1000,
          blockClass: "custom-block-class",
          status: "success",
          title: "Sucesso",
          message: "Download do boleto efetuado com sucesso.",
        });
        this.loading = false;
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: error.message,
        });
        this.loading = false;
      }
    },

    deactivated() {
      this.divida = null;
      this.negociacao = null;
      this.selectDeal = null;
      this.digitalLine = null;
    },
    voltar() {
      this.$router.push({
        name: "dashboard",
      });
    },
    showDigitalLine() {
      this.digitalLine = this.selectDeal.linhaDigitavel;
    },

    selectAll() {
      var marcar = document.getElementById("all").checked;
      var lista = document.getElementsByClassName(
        "form-check-input check1 bg-primary"
      );
      for (var i = 0; i < lista.length; i++) {
        lista[i].checked = marcar;
      }
    },

    select() {
      var lista = document.getElementsByClassName(
        "form-check-input check1 bg-primary"
      );
      var all = document.getElementById("all");
      if (lista.length <= 2) {
        all.checked = false;
      }
    },

    clear() {
      var lista = document.getElementsByClassName(
        "form-check-input check1 bg-primary"
      );
      for (var i = 0; i < lista.length; i++) {
        lista[i].checked = false;
      }
    },

    propostas() {
      this.$router.push({
        name: "propostas",
        params: { contracts: this.contractFull },
      });
    },
    sumTotalInstallments() {
      let totalInstallments = 0;
      this.agreements.map((value) => {
        value.ParcelasAcordo.ParcelaAcordo.map(() => {
          totalInstallments += 1;
        });
      });

      return totalInstallments;
    },

    verBoletos() {
      this.$router.push({
        name: "meusBoletos",
        params: {
          agreements: this.contracts.agreements,
          contracts: this.contracts,
        },
      });
    },

    contractsPending() {
      if (this.contracts.debts) {
        localStorage.setItem("contracts", JSON.stringify(this.contracts));
        this.$router.push({
          name: "meusContratos",
          params: { contracts: this.contracts.debts ? this.contracts : [] },
        });
      }
    },

    cadastro() {
      this.$router.push({
        name: "cadastro",
        params: {
          agreements: this.contracts.agreements,
          contracts: this.contracts,
        },
      });
    },
  },
  async created() {
    this.loadDeal = true;
    let contractsFromLocalStorage = JSON.parse(
      localStorage.getItem("contracts")
    );
    let agreementsFromLocalStorage = JSON.parse(
      localStorage.getItem("agreements")
    );
    this.contracts = contractsFromLocalStorage || {};
    this.agreements = agreementsFromLocalStorage || [];

    // this.contract = this.contractFull[0].environments[0].contracts
    this.loadDeal = false;
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>

